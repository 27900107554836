<template>
  <div style="width:100%">
    <v-row no-gutters align="center" justify="center" style="margin:50px 0 70px;width:100%;" id="priceMenuContainer">
      <v-col cols="1" sm="1" md="1" lg="2" xl="3"></v-col>
      <v-col cols="10" sm="10" md="10" lg="8" xl="6">
        <div style="font-weight: bold;font-size: 20px;margin-bottom: 10px;">購買VIP方案</div>
        <v-card color="basil" flat>
          <v-row no-gutters>
            <v-col cols="12" sm="12" md="3" lg="3" v-for="item in $store.state.plans" :key="item.plan_type">
              <div style="padding:8px;">
                <v-hover v-slot:default="{ hover }">
                  <v-card
                    :elevation="hover ? 5 : 1"
                    outlined
                    :class="{ 'on-hover': hover }"
                    style="cursor:pointer;border-radius: 20px;max-width:320px;"
                    @click="showDialog(item)"
                  >
                    <v-card-text>
                      <CardItem :itemObj="item"></CardItem>
                    </v-card-text>
                  </v-card>
                </v-hover>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="2" xl="3"></v-col>
    </v-row>
  </div>
</template>

<script>
import CardItem from "@/components/sub_components/PriceMenu_NewItem.vue";
export default {
  data() {
    return {};
  },
  components: {
    CardItem
  },
  methods: {
    showDialog(item) {
      this.leaveFocus();
      this.$emit("showBuyDialog", item);
    },
    leaveFocus() {
      if (document.activeElement != document.body) {
        document.activeElement.blur();
      }
    }
  }
};
</script>

<style scoped></style>
